<template>
  <div class="flex h-full">
    <!-- Sidebar -->
    <div
      class="w-60 bg-white dark:bg-slate-900 flex flex-col border-r border-slate-200 dark:border-slate-700 hidden md:flex">
      <header class="flex flex-row px-4 mb-4">
        <img style="max-width: 75%;" src="/brand-assets/metrobot_combined.svg" alt="MetroBotLogo">
        <woot-button v-tooltip.right="`Yeni Sohbet`" size="xxlarge" color-scheme="info" icon="comment-add"
          class="clear ml-auto mt-3" @click="onNewChat"></woot-button>
      </header>
      <div class="flex-1 overflow-y-auto mb-14">
        <div class="py-2 px-4">
          <li v-if="isHistoryLoading" class="text-center">
            <span class="mt-4 mb-4 spinner"></span>
          </li>

          <div v-else v-for="opt in chatLongTermMemory" :key="opt.id" class="group flex flex-row items-center justify-between cursor-pointer space-y-0.5 hover:bg-slate-25 dark:hover:bg-slate-800 hover:text-slate-700 dark:hover:text-slate-100">
            <span @click="openChat(opt.id)"
              class="flex items-center p-2 m-0 text-xs font-light leading-4 rounded-lg text-slate-700 dark:text-slate-100"
              :class="{ 'bg-slate-700': conversationId === opt.id }" :title="opt.title.length > 30 ? opt.title : ''">
              {{ getFixedTitle(opt.title) }}
            </span>
            <woot-button size="small" @click="showDeleteChatModal(opt)" color-scheme="secondary" icon="delete" 
              :is-loading="loading[opt.id]"
              class="items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200" ></woot-button>
          </div>
          
        </div>
      </div>
      <div v-show="showSettings" class="mt-4">
        <select v-tooltip.top-start="`LLM Model`" class="cursor-pointer" v-model="llmModelSelected"
          @change="setChatSetting('llm_model')">
          <option selected value="LLM Model" :disabled="true">LLM Model</option>
          <option v-for="opt in llmModels" :key="opt.id" :value="opt.name" :disabled="opt.disabled">{{ opt.name }}
          </option>
        </select>
        <select v-tooltip.top-start="`Konuşma Tonu`" class="cursor-pointer" v-model="speakToneSelected"
          @change="setChatSetting('speaking_tone')">
          <option selected value="Konuşma Tonu" :disabled="true">Konuşma Tonu</option>
          <option v-for="opt in speakTones" :key="opt.id" :value="opt.name" :disabled="opt.disabled">{{ opt.name
            }}
          </option>
        </select>
        <select v-tooltip.top-start="`Yaratıcılık`" class="cursor-pointer" v-model="creativityModeSelected"
          @change="setChatSetting('creativity_mode')">
          <option selected value="Yaratıcılık" :disabled="true">Yaratıcılık</option>
          <option v-for="opt in creativityModes" :key="opt.id" :value="opt.name" :disabled="opt.disabled">{{ opt.name }}
          </option>
        </select>
      </div>
      <woot-button color-scheme="info" icon="settings" class="clear mb-6" size="xlarge"
        @click="showSettings = !showSettings">Ayarlar</woot-button>
    </div>
    <!-- Main Chat Area -->

    <div class="flex-col bg-black dark:bg-black mx-0 sm:mx-16 2xl:mx-60 max-w-80">
      <div class="flex flex-col bg-black dark:bg-black m-0 h-full min-h-0 min-w-50p" style="width: 63rem;">
        <!-- <img ref="gif" src="/brand-assets/bilgebot_avatar_1loop.gif" alt="Robot" class="robot" @load="hideGif"> -->
        <ul ref="conversationPanel" class="conversation-panel space-y-6 w-[32rem] sm:w-auto">
          <li v-for="message in chatHistory" :key="message.id"
            :style="message.direction === 'left' ? 'flex-direction: row;' : 'flex-direction: row-reverse;'">
            <div v-if="message.direction === 'left'" class="avatar max-h-12 overflow-clip	hidden sm:flex">
              <img style="height: 70%;" :src="getAvatarByMessage(message)" alt="metrobot">
            </div>
            <div class="msg" :class="{ 'leftmsg': message.direction === 'right' }"
              :style="message.direction === 'left' ? 'background:transparent;' : 'background:#c1c8cdff;'">
              <div v-html="message.content" :style="message.direction === 'left' ? 'color:white;' : 'color:black;'">
              </div>
            </div>
            <!-- <div v-if="message.source_message" class="infobox max-h-12 sm:flex">
              <img style="height: 40%;" src="/brand-assets/infoico.png" v-tooltip.top-end="message.source_message">
            </div> -->
          </li>
          <li v-if="isReplyLoading" class="text-center">
            <span class="mt-4 mb-4 spinner" />
          </li>
        </ul>
        <label class="block relative max-w-80 sm:max-w-3xl xl:max-w-3xl min-[1537px]:max-w-full">
          <textarea rows="3"
            class="resize-y max-h-40 border rounded w-full 
            py-2 pl-3 pr-9 text-slate-700 outline-none overflow-hidden border-black-200 hover:border-black-300 focus:border-black-300
            scroll-smooth lg:overflow-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50"
            placeholder="MetroBot'a sorunuz" v-model="prompt" @keydown="handleKeydown"></textarea>
          <button
            class="mt-4 absolute inset-y-0 right-3 max-h-8 rounded-lg border border-white bg-white p-0.5 text-black transition-colors"
            data-testid="send-button" @click="sendMessage">
            <span class="text-black" data-state="closed">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black">
                <path style="color: black !important;" d="M7 11L12 6L17 11M12 18V7" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
          </button>
        </label>
      </div>
    </div>
    <woot-delete-modal :show.sync="isDeleteChatModalOpen" :on-close="hideDeleteChatModal"
      :on-confirm="confirmDeleteChat" title="Emin misin?" message="Silme işlemi geri alınamaz."
      confirm-text="Evet, sil!" reject-text="Hayır" />
  </div>
</template>

<script>
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import 'shared/helpers/ApiCookiHelper';
import alertMixin from 'shared/mixins/alertMixin';
import MarkdownIt from "markdown-it";

export default {
  components: {
    MultiselectDropdown,
  },
  mixins: [alertMixin],
  data() {
    return {
      markdown: new MarkdownIt({ html: true, linkify: true }),
      apiUrl: window.chatwootConfig.bilgebotApiUrl + "/",
      apiChatListEndpoint: "chat",
      apiSettingsEndpoint: "settings",

      isDeleteChatModalOpen: false,
      isReplyLoading: false,
      isHistoryLoading: true,
      showSettings: false,

      loading: {},

      conversationId: "",
      conversationModel: 0,
      conversationTone: 0,
      conversationCreativity: 0,
      chatHistory: [],
      chatLongTermMemory: [],
      prompt: "",
      llmModelSelected: "Gemini",
      speakToneSelected: "Açıklayıcı",
      creativityModeSelected: "Dengeli",
      llmModels: [],
      speakTones: [],
      creativityModes: [],
      creativityModeTooltip: "Kaşif: Beklenmedik ve yenilikçi fikirler üretme eğilimindedir. Sanatsal ifade, hikaye anlatımı veya yeni fikirler keşfetmek için mükemmeldir. Bilgi tabanına dayanmayabilir, güvenli değildir.\n\nDengeli: Yaratıcılık ve güvenilirlik arasında dengeli bir yaklaşım sunar. Hem bilgiye dayalı yanıtlar üretir hem de yeni fikirler ve çözümler sunma kapasitesine sahiptir.\n\nDikkatli: Tutarlı ve güvenli çıktılar üretir. Bilgi tabanına dayanarak, minimal risk alarak soruları yanıtlar. Doğruluk odaklı içerikler için idealdir.",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
      currentUserID: 'getCurrentUserID',
    }),
  },
  watch: {},
  async mounted() {
    this.chatHistory = this.getDefaultMessage();
    await this.getChatHistory();
    await this.getChatSettings();
  },
  methods: {
    showDeleteChatModal(response) {
      this.isDeleteChatModalOpen = true;
      this.toDeleteSelectedChat = response;
    },
    hideDeleteChatModal() {
      this.isDeleteChatModalOpen = false;
    },
    async confirmDeleteChat() {
      this.hideDeleteChatModal();
      await this.deleteConversation(this.toDeleteSelectedChat.id);
    },
    async deleteConversation(id) {
      this.loading[id] = true;
      await axios.delete(`${this.apiUrl}${this.apiChatListEndpoint}/${id}`, { withCredentials: true })
        .then(() => {
          this.showAlert("Konuşma başarıyla silindi.");
        })
        .catch(() => {
          this.showAlert(this.$t('Konuşma silinirken bir hata oluştu!'));
        })
        .finally(() => {
          this.chatLongTermMemory = this.chatLongTermMemory.filter(item => item.id !== id);
          this.loading[id] = false;
        })
      await this.getChatHistory();
    },
    scrollChatToBottom() {
      const ulElement = this.$refs.conversationPanel;
      ulElement.scrollTop = ulElement.scrollHeight;
    },
    hideGif() {
      const gif = this.$refs.gif;
      setTimeout(() => {
        gif.style.display = "none";
      }, 10 * 1000);
    },
    jsonToMarkdown(jsonData) {
      let markdown = "";

      // Create header dynamically from JSON keys
      const header = Object.keys(jsonData[0]);
      markdown += "| " + header.join(" | ") + " |\n";
      markdown += "| " + "-|".repeat(header.length) + "\n";

      // Iterate over JSON data and create rows dynamically
      jsonData.forEach(item => {
        const values = Object.values(item);
        markdown += "| " + values.join(" | ") + " |\n";
      });

      return markdown;
    },
    mdToHtml(message) {
      let content = message.content;

      // Extract the JSON string from the content
      let jsonStartIndex = content.indexOf('```json') + '```json'.length;
      let jsonEndIndex = content.indexOf('\n```', jsonStartIndex);
      let jsonString = content.substring(jsonStartIndex, jsonEndIndex);

      let formattedContent = "";
      let jsonData = "";
      try {
        jsonData = JSON.parse(jsonString);
      } catch (e) {
        let htmlContent = this.markdown.render(content);
        let parser = new DOMParser();
        let doc = parser.parseFromString(htmlContent, 'text/html');

        let links = doc.querySelectorAll('a');
        if (links.length > 0) {

          let lastLink = links[links.length - 1];
          let newLine = doc.createElement('br');
          lastLink.parentNode.insertBefore(newLine, lastLink);
          lastLink.target = '_blank';
          lastLink.style = "display:block;text-align:right;";
          lastLink.classList.add("text-woot-500");
          lastLink.classList.add("dark:text-woot-500");
          lastLink.classList.add("cursor-pointer");
          if (message.source_message === '') {
            lastLink.textContent = 'Kaynak Doküman';
          } else {
            lastLink.textContent = 'Kaynak Doküman';
          }
        }
        let serializer = new XMLSerializer();
        let updatedHtmlContent = serializer.serializeToString(doc);
        console.log(updatedHtmlContent);
        return updatedHtmlContent;
      }
      try {
        let mdTable = this.jsonToMarkdown(jsonData);
        formattedContent = content.substring(0, jsonStartIndex - 7) + '\n' + mdTable + content.substring(jsonEndIndex + 4);
      } catch (e) {
        try {
          let formattedJson = JSON.stringify(jsonData, null, 2);
          formattedContent = content.substring(0, jsonStartIndex) + '\n' + formattedJson + content.substring(jsonEndIndex);
        } catch (e2) {
          formattedContent = content;
        }
      }
      let htmlContent = this.markdown.render(formattedContent);

      let parser = new DOMParser();
      let doc = parser.parseFromString(htmlContent, 'text/html');

      let links = doc.querySelectorAll('a');
      if (links.length > 0) {
        let lastLink = links[links.length - 1];
        let newLine = doc.createElement('br');
        lastLink.parentNode.insertBefore(newLine, lastLink);
        lastLink.target = '_blank';
        lastLink.textContent = 'Kaynak Doküman';
      }
      let serializer = new XMLSerializer();
      let updatedHtmlContent = serializer.serializeToString(doc);

      console.log(updatedHtmlContent);
      
      return updatedHtmlContent;
    },
    processMessageContent(content) {
      // Regular expression to match JSON blocks wrapped in triple backticks
      const jsonBlockPattern = /```json\s*([^]+?)```/g;

      // Replace JSON blocks with wrapped scrollable divs
      const processedContent = content.replace(jsonBlockPattern, (match, jsonContent) => {
        return `<div style="height: 200px; overflow: auto;">${jsonContent.trim()}</div>`;
      });
      return processedContent;
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {       
        if (event.shiftKey || event.ctrlKey) {
          // Shift + Enter: Insert newline
          const textarea = event.target;
          const start = textarea.selectionStart;
          const end = textarea.selectionEnd;
          this.prompt = this.prompt.substring(0, start) + '\n' + this.prompt.substring(end);
          this.$nextTick(() => {
            textarea.selectionStart = textarea.selectionEnd = start + 1;
          });
          event.preventDefault();
        } else {
          // Enter: Send message
          this.sendMessage();
          event.preventDefault();
        }
      }
    },
    async setChatSetting(settingKey) {
      if (!this.conversationId) return;
      let settingId;
      switch (settingKey) {
        case 'llm_model':
          settingId = this.findDefaultId(this.llmModels, this.llmModelSelected);
          break;
        case 'speaking_tone':
          settingId = this.findDefaultId(this.speakTones, this.speakToneSelected);
          break;
        case 'creativity_mode':
          settingId = this.findDefaultId(this.creativityModes, this.creativityModeSelected);
          break;
        default:
          return;
      }
      try {
        await axios.get(`${this.apiUrl}${this.apiSettingsEndpoint}/${this.conversationId}/${settingKey}/${settingId}`, { withCredentials: true });
      } catch (error) {
        console.error(`Error setting ${settingKey}:`, error);
      }
    },
    async getChatSettings() {
      try {
        const res = await axios.get(`${this.apiUrl}${this.apiSettingsEndpoint}`, { withCredentials: true });
        this.llmModels = res.data.llm_models;
        this.speakTones = res.data.speaking_tones;
        this.creativityModes = res.data.creativity_modes;
        this.setSelectedsById();
      } catch (err) {
        console.error("Error fetching chat settings:", err);
      }
    },
    async getChatHistory() {
      try {
        const res = await axios.get(`${this.apiUrl}${this.apiChatListEndpoint}?account=${this.currentAccountId}`, { withCredentials: true });
        this.chatLongTermMemory = res.data.map(d => ({ id: d.id, title: d.title }));
      } catch (err) {
        console.error("Error fetching chat history:", err);
      } finally {
        this.isHistoryLoading = false;
      }
    },
    setSelectedsById(modelId = 1, toneId = 1, creativityId = 1, conversation = false) {
      this.llmModelSelected = this.findDefaultStr(this.llmModels, modelId);
      this.speakToneSelected = this.findDefaultStr(this.speakTones, toneId);
      this.creativityModeSelected = this.findDefaultStr(this.creativityModes, creativityId);
      if (conversation) {
        this.conversationModel = modelId;
        this.conversationTone = toneId;
        this.conversationCreativity = creativityId;
      }
    },
    findDefaultStr(listData, id) {
      const item = listData.find(opt => opt.id === id);
      return item ? item.name : '';
    },
    findDefaultId(listData, name) {
      const item = listData.find(opt => opt.name === name);
      return item ? item.id : null;
    },
    getFixedTitle(title) {
      if (!title) return;
      return title.length > 30 ? `${title.substring(0, 30)} ...` : title;
    },
    getDefaultMessage() {
      return [{ content: 'Merhaba ben MetroBot, İstanbul Metrosuyla ilgili kurumsal sorularınızı yanıtlamak için buradayım.\nSize nasıl yardımcı olabilirim?', direction: 'left' }];
    },
    async onNewChat() {
      this.chatHistory = this.getDefaultMessage();
      this.conversationId = "";
      await this.getChatSettings();
    },
    async sendMessage() {
      if (!this.prompt.trim() || this.prompt.trim().length <= 3) {
        this.showAlert("3 Haneden daha uzun olmalı.");
        return
      };
      const contentData = this.prompt.trim();
      this.prompt = "";
      this.chatHistory.push({ content: this.mdToHtml({content: contentData}), direction: 'right' });
      this.$forceUpdate();
      this.$nextTick(() => {
        this.scrollChatToBottom();
      });
      this.isReplyLoading = true;

      try {
        const res = await axios.post(this.apiUrl, {
          custom_attributes: {
            llm_model: this.findDefaultId(this.llmModels, this.llmModelSelected),
            speaking_tone: this.findDefaultId(this.speakTones, this.speakToneSelected),
            creativity_mode: this.findDefaultId(this.creativityModes, this.creativityModeSelected),
          },
          event: "message_created",
          message_type: "incoming",
          content: contentData,
          account_id: this.currentAccountId,
          user_id: this.currentUserID,
          username: this.currentUser.name,
          conversation_id: this.conversationId,
        }, { withCredentials: true });

        const resData = res.data || { response: "NO DATA PROVIDED" };
        this.chatHistory.push({ content: this.mdToHtml({content: resData.response, source_message: resData.source_message}), direction: 'left', source_message: resData.source_message });
        this.conversationId = resData.conversation_id || "";
        if (!this.conversationId) {
          await this.getChatHistory(); 
        }

      } catch (err) {
        console.error("Error sending message:", err);
      } finally {
        this.isReplyLoading = false;
        this.$nextTick(() => {
          this.scrollChatToBottom();
        });
        this.$forceUpdate();
      }
    },
    async openChat(id) {
      if (this.conversationId === id) return;
      try {
        const res = await axios.get(`${this.apiUrl}${this.apiChatListEndpoint}/${id}`, { withCredentials: true });
        this.chatHistory = res.data.data.map(msg => ({
          id: msg.id,
          content: this.mdToHtml(msg),
          direction: msg.message_type === 0 ? 'right' : 'left',
        }));
        this.setSelectedsById(res.data.llm_model, res.data.speaking_tone, res.data.creativity_mode, true);
        this.conversationId = id;
      } catch (err) {
        console.error("Error opening chat:", err);
      }
    },
    getAvatarByMessage(msg) {
      return msg.direction === 'right' ? '/assets/administrate/user/avatar.png' : '/brand-assets/bilgebot_cropped.png';
    },
    onModelSelected() {
    }
  },
};
</script>
<style scoped lang="css">
.robot {
  position: fixed;
  bottom: 20%;
  left: 20%;
}


.profiler-results {
  display: none;
}

.avatar {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  color: var(--text-color);
  height: 3rem;
  justify-content: center;
  margin: 3px;
  overflow: hidden;
  width: 3rem
}

.infobox {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  color: var(--text-color);
  height: 3rem;
  justify-content: center;
  margin: 3px;
  overflow: hidden;
  width: 3rem;
  position: relative;
  right: 1rem;
  bottom: .2rem;
}


.msg {
  display: inline-block;
  background: #c1c8cdff;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;

  min-height: 1.5rem;
}

.msg p {
  color: black;
}

.msg:after {
  content: "";
  top: 0;
  position: absolute;
  border: .75em solid transparent;
  border-top-color: transparent;
  display: block;
  left: 3.1rem
}

.msg.leftmsg:after {
  margin-right: 2rem;
  right: 3.1rem;
  left: auto
}
</style>
<style lang="scss" scoped>
.conversation-details-wrap {
  @apply flex flex-col min-w-0 w-full;

  &.with-border-right {
    @apply border-r border-slate-50 dark:border-slate-700;
  }
}
</style>

<style lang="scss" scoped>
.conversation-panel {
  @apply flex-shrink flex-grow basis-px flex flex-col overflow-y-auto relative h-full m-0 mb-4  pb-4;
}

.conversation-panel>li {
  @apply flex flex-shrink-0 flex-grow-0 flex-auto max-w-full mt-0 mr-3 mb-1 ml-0 relative first:mt-auto last:mb-0;

  &.left {
    .bubble {
      @apply rounded-r-lg rounded-l mr-auto break-words;

      &:not(.is-unsupported) {
        @apply border border-slate-50 dark:border-slate-700 bg-white dark:bg-slate-700 text-black-900 dark:text-slate-50;
      }
    }

    +.right {
      @apply mt-2.5;

      .bubble {
        @apply rounded-tr-lg;
      }
    }
  }

  &.right {
    @apply justify-end;

    .wrap {
      @apply flex items-end mr-4 text-right;
    }

    .bubble {
      @apply ml-auto break-words rounded-l-lg rounded-r;
    }

    +.left {
      @apply mt-2.5;

      .bubble {
        @apply rounded-tl-lg;
      }
    }
  }

  &.center {
    @apply items-center justify-center;
  }
}
</style>
<style lang="css" scoped>
select {
  border-color: transparent;
  font-size: small;
}

select:focus {
  border-color: transparent !important;
}

option {
  font-weight: 300;
  font-size: small;
}
</style>
<style scoped lang="scss">
:deep(table) {
  @apply mb-4;

  border-style: solid;
  border-color: #ffffff14;
  border-width: 1px;
}

:deep(table tbody tr:is(.dark *)) {
  border-color: hsl(255 0.6% 50.8% / 1.3);
}

:deep(pre) {
  @apply overflow-y-scroll max-h-56 mb-3;

  background-color: #f4f4f41A;
  padding: 10px;
  border-radius: 5px;
  overflow-y: scroll !important;
  max-height: 14rem !important;
}

textarea {
  line-height: 1.5;
  height: auto;
  border-radius: 10px;
}

/* width */
:deep(::-webkit-scrollbar) {
  width: .375rem;
  height: .2rem;
  background: transparent;
}

/* Track */
:deep(::-webkit-scrollbar-track) {
  background: transparent;
}

/* Handle */
:deep(::-webkit-scrollbar-thumb) {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
:deep(::-webkit-scrollbar-thumb:hover) {
  background: #555;
}
</style>